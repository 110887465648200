<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row justify="center">
      <!-- <v-col cols="12">
        <base-material-card
          icon="mdi-archive-arrow-down-outline"
          title="Presupuestos"
          class="px-5 py-3"
          color="orange"
        >
          <pre>{{ warhouseMovements }}</pre>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busca por cualquier campo"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :loading="fetchingBusinessSales"
            :search="search"
            :items="businessSales"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template v-slot:top>
              <sales-table-top />
            </template>
            <template v-slot:[`item.description`]="{ item: input }">
              <span v-if="input.description">
                {{ input.description }}
              </span>
              <span v-else>
                ...
              </span>
            </template>
            <template v-slot:[`item.total`]="{ item: input }">
              <span>
                {{ input.totalAmountCurrencyReference | formatPrice }}
              </span>
            </template>
            <template v-slot:[`item.tr`]="{}">
              <span> 0 </span>
            </template>
            <template v-slot:[`item.tk`]="{}">
              <span> 0 </span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item: input }">
              {{ new Date(input.createdAt).getDate() }}/{{ new Date(input.createdAt).getMonth() + 1 }}/{{ new Date(input.createdAt).getFullYear() }}
            </template>
            <template v-slot:[`item.actions`]="{ item: item }">
              <sales-actions
                :item="item"
              />
            </template>
          </v-data-table>
        </base-material-card>
      </v-col> -->
      <v-col cols="12">
        <base-material-card
          icon="mdi-archive-arrow-down-outline"
          title="Despachos"
          class="px-5 py-3"
          color="orange"
        >
          <!-- <pre>{{ warhouseMovements }}</pre> -->
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busca por cualquier campo"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="concessionsRequest"
            :search="search"
            :loading="fetchingWarehouseConcessions"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template v-slot:top>
              <sales-table-top />
            </template>
            <template v-slot:[`item.description`]="{ item: input }">
              <span v-if="input.description">
                {{ input.description }}
              </span>
              <span v-else>
                ...
              </span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item: input }">
              {{ new Date(input.createdAt).getDate() }}/{{ new Date(input.createdAt).getMonth() + 1 }}/{{ new Date(input.createdAt).getFullYear() }}
            </template>
            <template v-slot:[`item.total`]="{ item: input }">
              <span>
                {{ input.totalAmountCurrencyReference | formatPrice }}
              </span>
            </template>
            <template v-slot:[`item.tr`]="{}">
              <span> 0 </span>
            </template>
            <template v-slot:[`item.tk`]="{}">
              <span> 0 </span>
            </template>
            <template v-slot:[`item.actions`]="{ item: item }">
              <sales-actions
                :item="item"
                concession
              />
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
      <v-col cols="12">
        <base-material-card
          icon="mdi-archive-arrow-down-outline"
          title="Devoluciones"
          class="px-5 py-3"
          color="orange"
        >
          <!-- <pre>{{ warhouseInputs }}</pre> -->
          <v-data-table
            :headers="headers"
            :items="warhouseInputs"
            :loading="fetchingWarehouseInputs"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template
              v-if="showAdd"
              v-slot:[`body.prepend`]="{}"
            >
              <br>
              <tr>
                <th />
                <!-- <th>
                  !-- <template
                    @click="dialogA = true"
                  >
                    <v-autocomplete
                      v-model="data.provider"
                      :items="getProviders"
                      item-text="name"
                      return-object
                      :loading="fetchingProviders"
                      :disabled="fetchingProviders"
                      outlined
                      label="Proveedor"
                    />
                  </template>
                </th> -->
                <th colspan="2">
                  <v-autocomplete
                    v-model="productSelected"
                    label="Productos"
                    :loading="fetchingProducts"
                    :disabled="fetchingProducts"
                    :items="getProducts"
                    item-text="name"
                    no-data-text="No hay coincidencia"
                    return-object
                    outlined
                  />
                  <v-row>
                    <v-col>
                    <!-- :label="'Cantidad de ' + packingUnit" -->
                    </v-col>
                    <v-col>
                    <!-- :label="'Cantidad de ' + saleUnit" -->
                    </v-col>
                  </v-row>
                </th>
                <th>
                  <v-text-field
                    v-model="packingCant"
                    type="number"
                    min="1"
                    outlined
                    label="Rollos"
                    required
                    :hint="'Disponible: ' + getCantPackingBySelectProduct"
                    persistent-hint
                  />
                </th>
                <th>
                  <v-text-field
                    v-model="cant"
                    type="number"
                    min="1"
                    outlined
                    label="KG"
                    required
                    persistent-hint
                    :hint="'Disponible :' + getCantbySelectProduct"
                  />
                </th>
                <!-- <th>
                  <v-text-field
                    v-model="data.guideNumber"
                    outlined
                    name="input-7-4"
                    label="# Guía"
                    no-resize
                  />
                </th> -->
                <th>
                  <v-text-field
                    v-model="data.description"
                    outlined
                    name="input-7-4"
                    label="Observaciones"
                    no-resize
                  />
                </th>

                <th>
                  <v-btn
                    icon
                    class="transparent-button-icon"
                    type="submit"
                    color="success"
                    :disabled="disabledSave"
                    :loading="fetchingProducts"
                    @click="submit"
                  >
                    <v-icon size="28">
                      mdi-check-all
                    </v-icon>
                  </v-btn>
                </th>
              </tr>
            </template>
            <template v-slot:top>
              <sales-table-top />
              <v-row>
                <v-col cols="10" />
                <v-col>
                  <br>
                  <v-btn
                    :color="showAdd ? 'red' : 'orange'"
                    fab
                    large
                    class="v-btn--example"
                    @click="showAdd = !showAdd"
                  >
                    <v-icon v-if="showAdd">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.description`]="{ item: input }">
              <span v-if="input.description">
                {{ input.description }}
              </span>
              <span v-else>
                ...
              </span>
            </template>
            <template v-slot:[`item.total`]="{ item: input }">
              <span>
                {{ input.totalAmountCurrencyReference | formatPrice }}
              </span>
            </template>
            <template v-slot:[`item.tr`]="{}">
              <span> 0 </span>
            </template>
            <template v-slot:[`item.tk`]="{}">
              <span> 0 </span>
            </template>
            <template v-slot:[`item.approved`]="{ item }">
              <v-icon
                v-if="item.approved"
                color="green"
              >
                mdi-check-all
              </v-icon>
              <v-icon
                v-else
                color="grey"
              >
                mdi-check-all
              </v-icon>
            </template>
            <template v-slot:[`item.createdAt`]="{ item: input }">
              {{ new Date(input.createdAt).getDate() }}/{{ new Date(input.createdAt).getMonth() + 1 }}/{{ new Date(input.createdAt).getFullYear() }}
            </template>
            <template v-slot:[`item.actions`]="{ item: item }">
              <sales-actions
                :item="item"
                not-return
              />
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import SalesTableTop from './SalesTableTop'
  import SalesActions from './SalesActions.vue'
  import { TYPE_TRANSACTION, PRINCIPAL_WAREHOUSE } from '../../../../const'
  // import ConcessionRequestActions from './ConcessionRequestActions.vue'

  export default {
    name: 'ReturnsTable',

    components: {
      SalesTableTop,
      SalesActions,
      // ConcessionRequestActions,
    },

    filters: {
      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },
    },

    data () {
      return {
        showAdd: false,
        search: '',
        productSelected: null,
        cant: 20,
        packingCant: 1,
        warning: false,
        data: {
          description: '',
          products: [],
        },
        headers: [
          {
            text: 'Fecha',
            value: 'createdAt',
          },
          {
            text: 'Código',
            value: 'code',
          },
          {
            text: 'Total Rollos',
            value: 'totalPacking',
          },
          {
            text: 'Total KG',
            value: 'totalUnits',
          },
          {
            text: 'Total',
            value: 'total',
          },
          {
            text: 'Observaciones',
            value: 'description',
          },
          {
            text: 'Acciones',
            value: 'actions',
            align: 'center',
            sortable: false,
          },
        ],
      }
    },

    computed: {
      ...mapState([
        'fetchingBusinessSales',
        'businessSales',
        'warhouseInputs',
        'fetchingWarehouseInputs',
        'warehousesConcessions',
        'fetchingWarehouseConcessions',
        'fetchingProducts',
        'products',
        'storeInventory',
        'actionWarehouse',
        'warehouses',
        'user',
        'actionEnvironmentData',
        'actionsBusiness',
      ]),

      getProducts () {
        let res = this.products.filter(item => item.status && this.getProdusctAvailable(item.id || item.productId))
        res = res.map(item => {
          item.name = item.codigo + ' - ' + item.description
          item.productId = item.productId ? item.productId : item.id
          delete item.id

          return item
        })

        return res
      },

      concessionsRequest () {
        // // // // // // // // // // // // // // // // // // // // // console.log(this.warehousesConcessions)
        return this.warehousesConcessions.filter(item => item.typeTransactionId === TYPE_TRANSACTION.concesion)
      },

      getCantbySelectProduct () {
        let res = this.storeInventory.filter(item => item?.productId === this.productSelected?.productId)
        res = res[0]?.quantity_available || 0

        const chargedProduct = this.data.products.filter(item => item.productId === this.productSelected?.productId)
        return res - (chargedProduct[0]?.cant || 0)
      },

      getCantPackingBySelectProduct () {
        let res = this.storeInventory.filter(item => item?.productId === this.productSelected?.productId)
        res = res[0]?.quantity_packing_available || 0

        const chargedProduct = this.data.products.filter(item => item.productId === this.productSelected?.productId)
        return res - (chargedProduct[0]?.packingCant || 0)
      },

      disabledSave () {
        let res = false
        if (
          this.productSelected === null ||
          this.data.description === ''
        ) {
          res = true
        }

        return res
      },
    },

    methods: {
      ...mapActions([
        'fetchWarehouseSalesReturns',
        'fetchStoreInventory',
        'createWarehouseOutputs',
      ]),

      getProdusctAvailable (id) {
        const filter = this.storeInventory.filter(item => item?.productId === id)
        return filter.length > 0 && filter[0].quantity_available > 0
      },

      resetData () {
        this.data = {
          description: '',
          products: [],
        }
      },
      async afterCreate () {
        this.$emit('close')
        this.showAdd = false
        this.resetData()
        // await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
        await this.fetchWarehouseSalesReturns({ id: this.actionWarehouse })
        await this.fetchStoreInventory({ id: this.warehouses[0].warehouseId })
      },
      async submit (e) {
        e.preventDefault()
        if (this.disabledSave === false) {
          const data = {
            description: this.data.description,
            userId: this.user.id,
            typeTransactionId: TYPE_TRANSACTION.devolucion_por_concesion,
            warehouseId: PRINCIPAL_WAREHOUSE,
            relatedWarehouseId: this.actionWarehouse,
            parentId: null,
            products: [{ ...this.productSelected, cant: this.cant, packingCant: this.packingCant }],
            code: this.actionEnvironmentData.code,
            sucursalId: this.actionsBusiness,
          }
          await this.createWarehouseOutputs(data)
          this.afterCreate()
        }
      },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
