<template>
  <sales-table />
</template>

<script>
  import { mapActions, mapState, mapMutations } from 'vuex'
  import SalesTable from '../components/business-returns/SalesTable.vue'
  export default {
    name: 'BusinessReturns',
    components: {
      SalesTable,
    },
    computed: {
      ...mapState(['user', 'actionsParentBusiness', 'actionWarehouse', 'actionsBusiness', 'warehouses']),
    },

    watch: {
      async warehouses (v) {
        // // // // console.log('almacen de la tienda', v[0])
        // const wId = v.filter(item => )
        // // console.log('busco...', v)
        this.setActionWarehouse(v[0].warehouseId)
        await this.fetchStoreInventory({ id: v[0].warehouseId })
        // await this.getBusinessChartData({ warehouseId: v[0].warehouseId, businessId: this.actionsBusiness })
      },
    },

    async created () {
      this.setActions(Number(this.$route.params.id))
      this.setEnvironment('business')
      await this.getWarehousesByParentId({ id: this.$route.params.id })

      await this.fetchBusinessSales({ id: this.actionWarehouse, bussinessId: this.actionsBusiness })
      await this.fetchWarehouseConcessions({ id: this.actionWarehouse })
      await this.fetchWarehouseSalesReturns({ id: this.actionWarehouse })
      await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.getWarehousesByParentId({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductLine({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductClassification({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductCategory({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductType({ id: this.user.businessId || this.actionsParentBusiness })
    },

    methods: {
      ...mapActions([
        'fetchBusinessSales',
        'fetchWarehouseSalesReturns',
        'fetchWarehouseConcessions',
        // 'fetchWarehouseMovements',
        // 'fetchWarehouseInventory',
        // 'fetchProductLine',
        // 'fetchProductClassification',
        // 'fetchProductCategory',
        // 'fetchProductType',
        'fetchProducts',
        'getWarehousesByParentId',
        'getWarehousesByParentId',
        'fetchStoreInventory',
        'getBusinessChartData',
      ]),
      ...mapMutations({
        setEnvironment: 'SET_ACTIONS_ENVIRONMENT',
        setActions: 'SET_ACTIONS_BUSINESS',
        setActionWarehouse: 'SET_ACTIONS_WAREHOUSE',
      }),
    },
  }
</script>
