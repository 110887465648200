<template>
  <div>
    <v-tooltip
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProducts"
          color="light-blue darken-1"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="openedEditDialog = true"
        >
          <v-icon size="25">
            mdi-magnify-plus-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para ver el detalle del movimiento</span>
    </v-tooltip>

    <v-tooltip
      v-if="!notReturn"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProducts"
          color="red"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="openReturnDialog = true"
        >
          <v-icon size="25">
            mdi-archive-arrow-down-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para crear una devolución</span>
    </v-tooltip>

    <v-tooltip
      v-if="notReturn"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProducts"
          color="primary"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="downloadPdf"
        >
          <v-icon size="25">
            mdi-download
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para descargar la nota de devolución</span>
    </v-tooltip>

    <v-tooltip
      v-if="notReturn"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProducts"
          color="secondary"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="printPdf"
        >
          <v-icon size="25">
            mdi-printer
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para imprimir la nota de devolución</span>
    </v-tooltip>

    <!-- <v-btn
      color="yellow"
      class="transparent-button-icon"
      icon
    >
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn> -->
    <sales-detail-dialog
      :id="item"
      v-model="openedEditDialog"
      :concession="concession"
      :not-return="notReturn"
    />
    <new-return-dialog
      :id="item"
      v-model="openReturnDialog"
      :concession="concession"
    />
  </div>
</template>

<script>
  import SalesDetailDialog from './SalesDetailDialog.vue'
  import NewReturnDialog from './NewReturnDialog'
  import { mapState, mapActions } from 'vuex'
  import { jsPDF as JsPdf } from 'jspdf'
  import 'jspdf-autotable'

  export default {
    name: 'SalesActions',
    components: {
      SalesDetailDialog,
      NewReturnDialog,
    },
    props: {
      item: {
        type: Object,
        default () {
          return {}
        },
      },
      notReturn: {
        type: Boolean,
        default: () => false,
      },
      concession: {
        type: Boolean,
        default: () => false,
      },
    },
    data () {
      return {
        openedEditDialog: false,
        openReturnDialog: false,
      }
    },
    computed: {
      ...mapState([
        'fetchingWarehouseMovementsDetail',
        'warhouseMovementsDetail',
        'fetchingProducts',
        'user',
        'actionsParentBusiness',
      ]),
    },
    methods: {
      ...mapActions([
        'fetchWarehouseMovementsDetail',
        'fetchProducts',
        'deleteProducts',
        'activeProducts',
      ]),

      getImageByUrl (url) {
        return new Promise((resolve, reject) => {
          const img = new Image()
          img.onError = () => reject(
            new Error(`Cannot load image ${url}`),
          )
          img.onload = () => resolve(img)
          img.src = url
        })
      },

      async genPdf (print = false) {
        const doc = new JsPdf({
          orientation: 'portrait',
          format: 'letter',
        })
        // const img = await this.getImageByUrl('/logo.png') -> IMAGEN QUE QUITARON

        // // // // // // // console.log('ITEM', this.item)
        // // // // // // // // // // // // // // // // // // // // console.log('DETAIL', this.warhouseInputsDetail)

        // doc.addImage(img, 'PNG', 10, 5, 100, 20, 'logo')
        doc.setFontSize(38).text('Sistema Genérico', 10, 23)
        doc.setTextColor(255, 0, 0)
        doc.setFontSize(18).text(`CÓDIGO: ${this.item.code}`, 130, 20)
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12).text(`Fecha de emisión: ${this.formatDate(this.item.createdAt)}`, 130, 27)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(18).setTextColor(0, 0, 0).text(`Recepción de devolución de la sucursal ${this.item.warehouse}`, 45, 45)

        // // // // // // // console.log(this.warhouseMovementsDetail)

        doc.autoTable({
          headStyles: { fontSize: 14 },
          styles: { fontSize: 12, halign: 'center' },
          // columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } },
          columns: [
            { title: 'Tipo de tela', dataKey: 'description' },
            { title: 'Rollos', dataKey: 'packingCant' },
            // { title: 'Unidad de empaque', dataKey: 'packingUnit' },
            { title: 'KG', dataKey: 'cant' },
            { title: 'Motivo de la devolución', dataKey: 'motive' },
          ],
          body: this.warhouseMovementsDetail.map(item => {
            return {
              ...item,
              packingCant: Math.abs(item.packingCant),
              cant: Math.abs(item.cant),
              motive: this.item.description,
            }
          }),
          margin: {
            left: 10,
            top: 65,
          },
        })

        doc.setLineWidth(0.5).line(10, doc.internal.pageSize.height - 60, 70, doc.internal.pageSize.height - 60)
        doc.setFontSize(10).setTextColor(100, 100, 100).text('Firma de entrega', 25, doc.internal.pageSize.height - 53)

        doc.setLineWidth(0.5).line(145, doc.internal.pageSize.height - 60, 205, doc.internal.pageSize.height - 60)
        doc.setFontSize(10).setTextColor(100, 100, 100).text('Firma recibido', 164, doc.internal.pageSize.height - 53)

        if (print) {
          doc.autoPrint()
          doc.output('dataurlnewwindow')
        } else {
          doc.save('nota-de-devolucion.pdf')
        }
      },

      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },

      formatDate (dateProp) {
        const date = new Date(dateProp)

        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = new Date(year, month, date.getDate() + 1).getDate()

        if (month < 10) {
          return (`${day}/0${month}/${year}`)
        } else {
          return (`${day}/${month}/${year}`)
        }
      },

      async downloadPdf () {
        await this.fetchWarehouseMovementsDetail({ id: this.item.id })
        await this.genPdf()
      },

      async printPdf () {
        await this.fetchWarehouseMovementsDetail({ id: this.item.id })
        await this.genPdf(true)
      },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
