<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="warhouseMovementsDetail"
      :loading="fetchingWarehouseMovementsDetail"
      loading-text="Cargando... Por favor, espere"
      no-data-text="No hay datos disponibles"
      :footer-props="{
        'items-per-page-text': 'Filas por página',
        pageText: '{0}-{1} de {2}'
      }"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="fetchingProducts"
              color="red"
              class="transparent-button-icon"
              icon
              v-bind="attrs"
              v-on="on"
              @click="modal = true; prop = item"
            >
              <v-icon size="25">
                mdi-archive-arrow-down-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Presione para crear una devolución</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.cant`]="{ item }">
        {{ Math.abs(item.cant) }}
      </template>
      <template v-slot:[`item.packingCant`]="{ item }">
        {{ Math.abs(item.packingCant) }}
      </template>
      <template v-slot:[`item.amountWithoutTax`]="{ item }">
        {{ formatPrice(item.amountWithoutTax) }}
      </template>
      <template v-slot:[`item.subtotal`]="{ item }">
        {{ formatPrice(Math.abs(item.cant) * item.amountWithoutTax) }}
      </template>
    </v-data-table>

    <v-dialog
      v-model="modal"
      max-width="500"
    >
      <v-card>
        <v-card-title><b>Devolucion de: </b> {{ prop.description }}</v-card-title>
        <v-card-text>
          <v-container>
            <span
              v-if="error"
              class="red--text"
            >
              La devolucion no puede ser mayor a la cantidad vendida

            </span>
            <v-row
              class="mt-4"
            >
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  disabled
                  :value="Math.abs(prop.packingCant)"
                  :label="'Disponible de ' + prop.packingUnit"
                  type="number"
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="packingCant"
                  :max="Math.abs(prop.packingCant)"
                  min="1"
                  :label="'Cantidad de ' + prop.packingUnit"
                  type="number"
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  disabled
                  :value="Math.abs(prop.cant)"
                  :label="'Disponible de ' + prop.salesUnit"
                  type="number"
                  outlined
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="cant"
                  :max="Math.abs(prop.cant)"
                  min="1"
                  :label="'Cantidad de ' + prop.salesUnit"
                  type="number"
                  outlined
                />
              </v-col>
              <!-- <v-col
                cols="12"
              >
                <v-switch
                  v-model="enabled"
                  color="secondary"
                  label="¿Puede volver al inventario?"
                />
              </v-col> -->
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="description"
                  label="Observaciones"
                  outlined
                  no-resize
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="red darken-1"
            text
            @click="modal = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="secondary"
            @click="sendReturn"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <h3
      class="grey--text"
    >
      Pagos
    </h3>
    <v-data-table
      :headers="headersPayments"
      :items="salePayments"
      :loading="fetchingBusinessSales"
      loading-text="Cargando... Por favor, espere"
      no-data-text="No hay datos disponibles"
      :footer-props="{
        'items-per-page-text': 'Filas por página',
        pageText: '{0}-{1} de {2}'
      }"
    />
    <h3
      v-if="saleSurplus.length > 0"
      class="grey--text"
    >
      Cambios
    </h3>
    <v-data-table
      v-if="saleSurplus.length > 0"
      :headers="headersSurplus"
      :items="saleSurplus"
      :loading="fetchingBusinessSales"
      loading-text="Cargando... Por favor, espere"
      no-data-text="No hay datos disponibles"
      :footer-props="{
        'items-per-page-text': 'Filas por página',
        pageText: '{0}-{1} de {2}'
      }"
    /> -->
    <!-- {{ id }} -->
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { TYPE_TRANSACTION, PRINCIPAL_WAREHOUSE } from '../../../../const'
  export default {
    name: 'SalesDetailTable',

    props: {
      id: {
        type: Object,
        default () {
          return {}
        },
      },
      concession: {
        type: Boolean,
        default () {
          return false
        },
      },
      dialog: {
        type: Boolean,
        default () {
          return false
        },
      },
    },

    data () {
      return {
        modal: false,
        prop: {
          description: '',
        },
        cant: 20,
        packingCant: 1,
        enabled: true,
        description: '',
        error: false,
        headers: [
          {
            text: 'Codigo del producto',
            value: 'codigo',
          },
          {
            text: 'Nombre del producto',
            value: 'description',
          },
          {
            text: 'Rollos',
            value: 'packingCant',
          },
          // {
          //   text: 'Unidad de empaque',
          //   value: 'packingUnit',
          // },
          {
            text: 'KG',
            value: 'cant',
          },
          {
            text: 'Precio',
            value: 'amountWithoutTax',
          },
          {
            text: 'Total',
            value: 'subtotal',
          },
          {
            text: 'Acciones',
            value: 'actions',
          },
          // {
          //   text: 'Unidad',
          //   value: 'salesUnit',
          // },
        ],

      }
    },

    computed: {
      ...mapState([
        'fetchingWarehouseMovementsDetail',
        'warhouseMovementsDetail',
        'fetchingBusinessSales',
        'salePayments',
        'saleSurplus',
        'clientsSearch',
        'fecthingClients',
        'fetchingProducts',
        'fetchingWarehouseConcessions',
        'user',
        'actionWarehouse',
        'warehousesConcessions',
        'fetchingWarehouseConcessions',
        'actionEnvironmentData',
        'actionsBusiness',
      ]),
    },

    watch: {
      async dialog (v) {
        // // // // // // // // // // // // // // // // // // // // // console.log(v)
        if (v) {
          await this.fetchWarehouseMovementsDetail({ id: this.id.id })
          if (!this.concession) {
            await this.fetchClientById(this.id.clientId)
            await this.fetchBusinessSalesPayments({ id: this.id.id })
            await this.fetchBusinessSalesSurplus({ id: this.id.id })
          }
        }
      },
    },

    async mounted () {
      // // // // // // // // // // // // // // // // // // // // // console.log('id', this.id)
      await this.fetchWarehouseMovementsDetail({ id: this.id.id })
      if (!this.concession) {
        await this.fetchClientById(this.id.clientId)
        await this.fetchBusinessSalesPayments({ id: this.id.id })
        await this.fetchBusinessSalesSurplus({ id: this.id.id })
      }
    },

    methods: {
      ...mapActions([
        'fetchWarehouseMovementsDetail',
        'fetchBusinessSalesPayments',
        'fetchBusinessSalesSurplus',
        'fetchClientById',
        'createWarehouseConcessions',
        'fetchWarehouseSalesReturns',
      ]),

      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },

      async sendReturn () {
        // // // // // // // // // // // // // // // // // // // // // console.log(this.cant <= Math.abs(this.prop.cant))
        if (this.cant <= Math.abs(this.prop.cant)) {
          this.error = false
          delete this.prop.id
          const data = {
            description: this.description,
            userId: this.user.id,
            typeTransactionId: this.concession ? TYPE_TRANSACTION.devolucion_por_concesion : TYPE_TRANSACTION.devolucion_por_venta,
            warehouseId: this.concession ? PRINCIPAL_WAREHOUSE : this.actionWarehouse,
            relatedWarehouseId: this.concession ? this.actionWarehouse : PRINCIPAL_WAREHOUSE,
            parentId: this.id.id,
            products: [
              {
                ...this.prop,
                cant: this.cant,
                packingCant: this.packingCant,
                enabled: this.enabled,
                totalAmount: Math.abs(this.prop.totalAmount),
                totalAmountCurrencyReference: Math.abs(this.prop.totalAmountCurrencyReference),
                // wholesalePrices: Math.abs(this.prop.totalAmount) / Math.abs(this.prop.amountCurrencyReference),
                bigWholesalePrices: Math.abs(this.prop.amountWithoutTax),
              },
            ],
            code: this.actionEnvironmentData.code,
            sucursalId: this.actionsBusiness,
          }

          await this.createWarehouseConcessions(data)
          await this.fetchWarehouseSalesReturns({ id: this.actionWarehouse })

          this.modal = false
          this.prop = {
            description: '',
          }
          this.cant = 20
          this.packingCant = 1
          this.enabled = true
          this.description = ''
          this.error = false
        } else {
          this.error = true
          this.cant = 20
        }
      },
    },

  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
